import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from "axios"
import {
  FormGroup,
  Alert
} from "reactstrap";
import Reaptcha from "reaptcha";


export default function ContactForm() {
  const [isSuccess, setIsSuccess] = useState(false);
  const [visible, setVisible] = useState(false);
  const onDismiss = () => setVisible(false);
  const [recaptcha, setRecaptcha] = useState("");
  const [showValid, setShowValid] = useState(false);
  const { register, handleSubmit, errors, reset } = useForm();
  const onSubmit = data => {
      if(recaptcha.length < 1){
          setShowValid(true);
      }else{
          setShowValid(false);
          data.token = recaptcha;
          axios({
              method: "POST",
              url: process.env.API_URL,
              data: data,
              headers :{
                  'Content-Type': 'application/json'
              }
          }).then((response)=>{
              setIsSuccess(true);
              setRecaptcha("");
              setVisible(false);
              reset();
          })
          .catch((error)=>{
              console.log(error);
              setVisible(true);
          })
      }
  };

  const onVerify = recaptchaResponse => {
    setRecaptcha(recaptchaResponse);
  };

  return (
    <div className='contact-form'>
        {isSuccess ?
            <div className="success-form-submit">
                <h2>Vaša správa bola úspešne odoslaná</h2>
                <h3>Thank you for getting in touch!</h3>
                <p>Vážime si, že ste nás kontaktovali. Jeden z našich pracovníkov vás bude kontaktovať v krátkom čase!</p>
                <p>Prajeme vám pekný deň.</p>
                <button onClick={()=>setIsSuccess(false)} className="button-basic primary-invert">Poslať novú správu</button>
            </div>
            :
            <form onSubmit={handleSubmit(onSubmit)}>
                <legend>
                    <h2>Kontaktný formulár</h2>
                </legend>
       {/*  */}         <Alert color="danger" isOpen={visible} toggle={onDismiss} fade={false}>
                   Posím, skúste znova, odosielanie zlyhalo.
                </Alert>

                <div className="form-group">
                    <label htmlFor="name">Meno</label>
                    <input type="text" placeholder="Meno" className="form-control" name="name"
                           ref={register({required: true, maxLength: 80})}/>
                    {errors.name && <div className="requirements">Tento údaj je povinný</div>}
                </div>
                <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Emailová adresa</label>
                    <input type="text" className="form-control" placeholder="Email" name="email" ref={register({
                        required: true,
                        pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: "Nesprávny tvar emailovej adresy"
                        }
                    })}/>
                    {errors.email && <div className="requirements">{errors.email.message} Tento údaj je povinný</div>}
                </div>
                <div className="form-group">
                    <label htmlFor="message">Správa</label>
                    <textarea name="message" className="form-control" ref={register({required: true})} rows="5"/>
                    {errors.message && <div className="requirements">Tento údaj je povinný</div>}
                </div>

                <div className="form-group">
                    <Reaptcha sitekey={process.env.RECAPTCHA_API_KEY} onVerify={onVerify}/>
                    {showValid && <div className="requirements">Tento údaj je povinný</div>}
                </div>

                <FormGroup>
                    <div className="button-div">
                        <input type="submit" value="Odoslať" className="button-basic primary-invert"/>
                     </div>
                </FormGroup>
            </form>
        }
    </div>
  );
}
