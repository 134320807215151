import React from 'react';
import Layout from './index';
import { addLocaleData } from 'react-intl';

import messages from '../data/messages/sk';
import sk from 'react-intl/locale-data/sk';
import 'intl/locale-data/jsonp/en';

addLocaleData(sk);

export default (props) => (
  <Layout
    {...props}
    i18nMessages={messages}
  />
);