import React from "react";
import Layout from "../layouts/sk";
import MyMapComponent from "../components/map.js";
import ContactForm from "../components/contactForm-sk";
import {
  Button,
  Container,
  Row,
  Col
} from "reactstrap";
import SEO from "../components/seo.js";

const SecondPage = props => (
  <Layout location={props.location}>
    <div>
      <div className="container-fluid mq-xs" style={{ padding: 0 }}>
        <MyMapComponent
          isMarkerShown
          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBOh1wAZLx_f2riCJadaLxEpjUqkGX_Gy8&v=3.exp&libraries=geometry,drawing,places"
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `600px` }} />}
          mapElement={<div style={{ height: `100%` }} />}
        />
      </div>
      <Container fluid className="container-places margin-bottom mq-xs-b-0">
        <Row>
          <Col lg={6} md={12} className="form-contact col-basic">
            <ContactForm/>
          </Col>
          <Col lg={6} md={12} className="contact-info">
            <h2>Kontakt</h2>
            <Row>
              <Col md={6} sm={12}>
                <div className="">
                  <h5>Nová Polhora Invest, s.r.o.<br/></h5>
                  Štúrova 27<br/> 
                  040 01 Košice<br/>
                  Slovak Republic<br/><br/>
                </div>
              </Col>
              <Col md={6} sm={12}>
                <div className="">

<strong>IČO: </strong>
44312199<br/>
<strong>DIČ: </strong>
2022650223<br/>
<strong>IČ DPH: </strong>
SK2022650223 <br/> <br/>
Obchodný register Okresného súdu Košice I, <br/>
<strong>oddiel:</strong> Sro, vložka č. 22231/V

                </div>
              </Col>
            </Row>
          </Col>  
        </Row>
      </Container>
      <div className="button-div margin-button">
       <a href="https://goo.gl/maps/KRb7muyKHcsVYaU49"> <Button className="button-basic primary">
          Získaj súradnice 
        </Button></a>
      </div>
    </div>
    <SEO title="Kontaktujte nás" />
  </Layout>
);

export default SecondPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
